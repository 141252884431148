
















































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsLoader from '@/components/VsLoader/Index.vue'
import { AppModule } from '@/store/modules/app'
import { customerPortal } from '@/api/shop'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'DisabledUser',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    loading = false

    get user () {
        return UserModule.user
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get siteUrl () {
        return AppModule.siteUrl
    }

    get disabledReason () {
        return this.user?.disabledReason || 'ADMINISTRATIVE_PROBLEMS'
    }

    private openContact () {
        if (window.Beacon) {
            window.Beacon('open')
        } else {
            window.open(this.siteUrl + '/contatta-4dem/', '_blank')
        }
    }

    async openCustomerPortal () {
        this.loading = true
        try {
            const resp = await customerPortal({
                returnUrl: window.location.href,
            })
            window.location.href = resp.data.url
        } catch (e) {
            console.log(e)
            const statusCode = get(e, 'response.status', '')
            if (statusCode === 403) {
                this.$root.$vsToast({
                    heading: 'Non hai i permessi per completare questa azione',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
            this.loading = false
        }
    }
}
